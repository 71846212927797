/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

/***
 * GERAL
 ***/
$(function() {
    // Menu toggle
    var $win    = $(window);
        $header = $('header');
        $menu   = $('header nav');

    $win.scroll(function () {
        if ($win.scrollTop() > 100) {
            $header.addClass('mini');
        } else {
            $header.removeClass('mini');
        }
    });

    $('body').on('click', '.menuToggle', function(event) {
        event.preventDefault();
        $menu.toggle('fast');
        $('html').toggleClass('menu-open');
    });

    if ( $win.width() < 768) {
        $('header nav .nav li a.scroll').on('click', function(event) {
            event.preventDefault();
            $menu.toggle('fast');
            $('html').removeClass('menu-open');
        });
    };

    $( window ).resize(function(event) {
        $('html').removeClass('menu-open');
        if ($win.width() <= 767) {
            $menu.css('display', 'none');
        } else {
            $menu.css('display', 'block');
        };
    });

    // Input masks
    $(".cpf").mask("999.999.999-99");
    $(".cnpj").mask("99.999.999/9999-99");
    $(".data").mask("99/99/9999");
    $(".cep").mask("99999-999");
    $(".hora").mask("99:99");
    $('.telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');


    // Galeria
    $('.galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });

    $('.galeria').slick({
        slidesToShow    : 1,
        centerMode      : true,
        centerPadding   : '28%',
        prevArrow       : '<i class="icon-arrow-left"></i>',
        nextArrow       : '<i class="icon-arrow-right"></i>',

    });

    // banner
    $('.banner .itens').slick({
        slidesToShow    : 1,
        autoplay        : true,
        autoplaySpeed   : 4000,
        arrows          : false,
        dots            : true,
    });

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 150
    });

    // Scroll animation
    function scrollAnim (target) {
        $(target).animatescroll({
            scrollSpeed:1000,
            easing:'easeOutExpo',
            padding: 100
        });
    };

    // Header
    $('header .scroll, #frutamil .scroll').on('click', function(event) {
        event.preventDefault();
        scrollAnim($(this).data('target'));
    });

    // WOW
    wow = new WOW({
        offset: 10,
    });

    wow.init();


    // Stellar
    $(window).stellar();

    // Mapa
    $(".mapa").gmap3({
        map: {
            options: {
                scrollwheel: false,
                zoom: 16,
                zoomControlOptions: {style:google.maps.ZoomControlStyle.SMALL},
                streetViewControl: false,
                panControl: false,
                mapTypeControl: false,
                center: [-21.998118, -48.392943],
                styles: [{featureType: 'landscape',elementType: 'all',stylers: [{ hue: '#d3dbdb' },{ saturation: -63 },{ lightness: -5 },{ visibility: 'on' }]},{featureType: 'water',elementType: 'all',stylers: [{ hue: '#57d7d7' },{ saturation: 30 },{ lightness: -22 },{ visibility: 'on' }]},{featureType: 'poi',elementType: 'all',stylers: [{ hue: '#bed1d1' },{ saturation: -60 },{ lightness: 1 },{ visibility: 'on' }]},{featureType: 'road',elementType: 'all',stylers: [{ hue: '#afbbbb' },{ saturation: -92 },{ lightness: 19 },{ visibility: 'on' }]}],
            }
        },
        marker: {
            latLng: [-21.998118, -48.392943]
        }
    });

    // Imagem BG
    var controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
                duration: 300,
                triggerElement: "#imagem-bg",
                triggerHook: 0.5,
            })
            .setTween("#imagem-bg .logo", {opacity: '1'})
            .addTo(controller);

    // Produtos
    new ScrollMagic.Scene({
            duration: 300,
            triggerElement: "#produtos .inicio",
            triggerHook: 0.5,
            offset: 100
        })

        .setTween("#produtos .ilust img:nth-child(1)", {opacity: '1', top:'26%'})
        .addTo(controller);

    new ScrollMagic.Scene({
            duration: 300,
            triggerElement: "#produtos .inicio",
            triggerHook: 0.5,
            offset: 50
        })

        .setTween("#produtos .ilust img:nth-child(2)", {opacity: '1', top: '12%'})
        .addTo(controller);

    new ScrollMagic.Scene({
            duration: 300,
            triggerElement: "#produtos .inicio",
            triggerHook: 0.5,
            offset: -50
        })

        .setTween("#produtos .ilust img:nth-child(3)", {opacity: '1', top: '0%'})
        .addTo(controller);

    new ScrollMagic.Scene({
            duration: 300,
            triggerElement: "#produtos .inicio",
            triggerHook: 0.5,
            offset: 0
        })

        .setTween("#produtos .ilust img:nth-child(4)", {opacity: '1', top: '2%'})
        .addTo(controller);

    new ScrollMagic.Scene({
            duration: 300,
            triggerElement: "#produtos .inicio",
            triggerHook: 0.5,
            offset: 50
        })

        .setTween("#produtos .ilust img:nth-child(5)", {opacity: '1', top: '12%'})
        .addTo(controller);

    /* Mobile */
    if ( navigator.userAgent.match(/iPad/i) ) {
        $('#sr-gelo .ilust img:nth-child(1)').css('display', 'none');
    };

    // textarea
    var ta = document.querySelector('textarea');
    ta.addEventListener('focus', function(){
        autosize(ta);
    });

    // tirando autocomplete
    $('.wpcf7-form').attr('autocomplete', 'off').attr('autosuggest', 'off');

    /* <![CDATA[ */
    var _wpcf7 = {"recaptcha":{"messages":{"empty":"Please verify that you are not a robot."}}};
    /* ]]> */
});